import {
  Heading,
  LayoutGridContainer,
  LayoutGridItem,
  useMediumFromMediaQuery,
  useLargeFromMediaQuery,
} from '@madpaws/design-system';
import React from 'react';

import { FAQ } from './FAQ/FAQ';
import styles from './FAQs.module.css';

import type { Color } from '@madpaws/design-system/dist/components/Typography/Heading/Heading';

/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 */
const FAQS_HTML_ID = 'FAQs';

type Props = {
  faqs: {
    answer: string[];
    question: string;
  }[];
  headingColor?: Color;
};

export const FAQs = ({ faqs, headingColor = 'brandColor' }: Props): React.ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();
  const isLargeFromViewport = useLargeFromMediaQuery();

  if (!faqs || !faqs.length) {
    return <></>;
  }

  return (
    <section className={styles.root} id={FAQS_HTML_ID}>
      <LayoutGridContainer>
        <LayoutGridItem placement={{ small: [1, 13], medium: [2, 12], large: [3, 11] }}>
          <Heading
            alignment="centerAlign"
            color={headingColor}
            fontFamily="heading"
            size={isLargeFromViewport ? 'large1x' : isMediumFromViewport ? 'medium' : 'small1x'}
          >
            FAQs
          </Heading>
          {faqs.map((faq, index) => (
            <FAQ key={`faq_${index}`} {...faq} />
          ))}
        </LayoutGridItem>
      </LayoutGridContainer>
    </section>
  );
};
