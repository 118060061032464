import {
  Form,
  useDialogHeadingId,
  Button,
  IconPetSitting,
  IconPawPrint,
  AnimatedSpinner,
} from '@madpaws/design-system';
import { Formik } from 'formik';
import React from 'react';

import { searchWidgetValidationSchema } from '~/common/components/SearchBarWidget/MobileSearchWidgetWrapper/utils';
import { trackEvent } from '~/components/analytics/analytics';
import {
  UPDATE_FILTER_PETS_EVENT_NAME,
  UPDATE_FILTER_SERVICE_EVENT_NAME,
} from '~/components/analytics/constants';
import { SERVICE_OPTIONS } from '~/components/constants';

import styles from './SearchFiltersForm.module.css';
import { SEARCH_FILTERS_FORM_ID, SERVICE_AND_BOOKING_TYPE_FORM_ID } from '../constants';
import { BookingTypeField } from '../ui/BookingType/BookingTypeField';
import { DateSelector } from '../ui/DateSelector/DateSelector';
import { DialogFormField } from '../ui/DialogFormField/DialogFormField';
import { ErrorBoundary } from '../ui/ErrorBoundary/ErrorBoundary';
import { LocationField } from '../ui/Location/LocationField';
import { ServiceTypeField } from '../ui/ServiceType/ServiceTypeField';
import { YourPetsField } from '../ui/YourPets/YourPetsField';
import { transformPetSelectionToString, transformServiceOptionsToString } from '../utils';

import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

type Props = {
  currentValues: SearchFilters;
  onFilterSubmit: (searchFilters: SearchFilters) => void;
};

const SearchFiltersForm = ({ currentValues, onFilterSubmit }: Props): ReactElement => {
  const dialogHeadingId = useDialogHeadingId();

  const handleSubmit = (values: SearchFilters): void => {
    onFilterSubmit({
      ...currentValues,
      bookingType: values.bookingType,
      chronology: {
        startDate: values.chronology.startDate,
        endDate: values.chronology.endDate,
        scheduledDates: values.chronology.scheduledDates,
        weekDays: values.chronology.weekDays,
        rwbStartDate: values.chronology.rwbStartDate,
      },
      location: values.location,
      petTypes: values.petTypes,
      service: {
        type: values.service.type,
        quantity: 0,
      },
    });
  };

  return (
    <Formik
      initialValues={currentValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validationSchema={searchWidgetValidationSchema}
    >
      {(props): ReactElement => (
        <div className={styles.root}>
          <Form
            hasHtml5Validation={false}
            hasTighterSpacing
            id={SEARCH_FILTERS_FORM_ID}
            isGridRowGapDisabled={false}
            label={{ id: dialogHeadingId }}
          >
            <div className={styles.searchForm}>
              <div className={styles.location}>
                <ErrorBoundary fieldName="location">
                  <LocationField isLabelVisuallyHidden placeholder="Suburb or address" />
                </ErrorBoundary>
              </div>
              <div className={styles.petService}>
                <ErrorBoundary fieldName="service">
                  <DialogFormField
                    displayValue={transformServiceOptionsToString(
                      SERVICE_OPTIONS,
                      props.values.service.type,
                      props.values.bookingType
                    )}
                    handleSubmit={(): void => trackEvent(UPDATE_FILTER_SERVICE_EVENT_NAME)}
                    icon={<IconPetSitting />}
                    isLabelVisuallyHidden
                    label="Choose your service"
                    placeholder="Pet service"
                    visuallyHiddenLabel="To change the service type, click the field or press ENTER key to launch the service field in a dialog."
                  >
                    <Form id={SERVICE_AND_BOOKING_TYPE_FORM_ID} label={{ id: dialogHeadingId }}>
                      <ServiceTypeField />
                      <BookingTypeField />
                    </Form>
                  </DialogFormField>
                </ErrorBoundary>
              </div>

              <div className={styles.date}>
                <ErrorBoundary fieldName="chronology">
                  <DateSelector
                    dateFormat="fancy"
                    isCalendarIconHidden={false}
                    isLabelVisuallyHidden
                  />
                </ErrorBoundary>
              </div>

              <div className={styles.pets}>
                <ErrorBoundary fieldName="petTypes">
                  <DialogFormField
                    displayValue={transformPetSelectionToString(props.values.petTypes)}
                    handleSubmit={(): void => trackEvent(UPDATE_FILTER_PETS_EVENT_NAME)}
                    icon={<IconPawPrint />}
                    isLabelVisuallyHidden
                    label="Add your pets"
                    placeholder="Add pets"
                    visuallyHiddenLabel="To change the pet selection, click the field or press ENTER key to launch the pet field in a dialog."
                  >
                    <YourPetsField />
                  </DialogFormField>
                </ErrorBoundary>
              </div>

              <div className={styles.button}>
                <Button
                  iconLeading={props.isSubmitting ? <AnimatedSpinner size="medium" /> : undefined}
                  isDisabled={props.isSubmitting}
                  isFullBleed
                  label="Search"
                  onClick={(): Promise<void> => props.submitForm()}
                  variant="primary"
                />
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export { SearchFiltersForm };
