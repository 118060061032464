import { IconMinus, IconPlus } from '@madpaws/design-system';
import React, { useState } from 'react';

import styles from './FAQ.module.css';

type Props = {
  answer: string[];
  question: string;
};

export const FAQ = ({ answer, question }: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.root}>
      <button className={styles.question} onClick={toggleAnswer} type="button">
        {question} <div className={styles.icon}>{isOpen ? <IconMinus /> : <IconPlus />}</div>
      </button>
      {isOpen && (
        <div className={styles.answers}>
          {answer.map((para, index) => (
            <p key={`${question} answer ${index}`}>{para}</p>
          ))}
        </div>
      )}
    </div>
  );
};
