import { Form, useDialogHeadingId } from '@madpaws/design-system';
import React from 'react';

import styles from './SearchFiltersFormMobile.module.css';
import { SEARCH_FILTERS_FORM_ID } from '../constants';
import { BookingTypeField } from '../ui/BookingType/BookingTypeField';
import { DateSelector } from '../ui/DateSelector/DateSelector';
import { ErrorBoundary } from '../ui/ErrorBoundary/ErrorBoundary';
import { LocationField } from '../ui/Location/LocationField';
import { ServiceTypeField } from '../ui/ServiceType/ServiceTypeField';
import { YourPetsField } from '../ui/YourPets/YourPetsField';

import type { ReactElement } from 'react';

const SearchFiltersFormMobile = (): ReactElement => {
  const dialogHeadingId = useDialogHeadingId();

  return (
    <div className={styles.root}>
      <Form
        hasHtml5Validation={false}
        id={SEARCH_FILTERS_FORM_ID}
        isGridRowGapDisabled={false}
        label={{ id: dialogHeadingId }}
      >
        <div className={styles.location}>
          <ErrorBoundary fieldName="location">
            <LocationField />
          </ErrorBoundary>
        </div>
        <div className={styles.service}>
          <ErrorBoundary fieldName="service">
            <ServiceTypeField />
            <BookingTypeField />
          </ErrorBoundary>
        </div>
        <div className={styles.date}>
          <ErrorBoundary fieldName="chronology">
            <DateSelector dateFormat="fancy" />
          </ErrorBoundary>
        </div>
        <div className={styles.petTypes}>
          <ErrorBoundary fieldName="petTypes">
            <YourPetsField />
          </ErrorBoundary>
        </div>
      </Form>
    </div>
  );
};

export { SearchFiltersFormMobile };
