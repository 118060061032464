import { useLargeFromMediaQuery } from '@madpaws/design-system';
import { Formik } from 'formik';

import { MobileSearchWidget } from './MobileSearchWidget';
import { validateSearchWidget } from './utils';

import type { OnBeforeModalOpen } from './types';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

type Props = {
  handleFiltersSave: (searchFilters: SearchFilters) => void;
  onBeforeModalOpen?: OnBeforeModalOpen;
  searchFilters: SearchFilters;
};

export const MobileSearchWidgetWrapper = ({
  handleFiltersSave,
  searchFilters,
  onBeforeModalOpen,
}: Props): ReactElement | null => {
  const isLargeFromViewport = useLargeFromMediaQuery();

  if (isLargeFromViewport) {
    return null;
  }

  return (
    <Formik
      initialValues={searchFilters}
      onSubmit={handleFiltersSave}
      validate={validateSearchWidget}
      validateOnBlur={false}
    >
      <MobileSearchWidget onBeforeModalOpen={onBeforeModalOpen} />
    </Formik>
  );
};
