import { useMediumFromMediaQuery } from '@madpaws/design-system';
import dynamic from 'next/dynamic';

import type { ReactElement } from 'react';

const HowDoesMadPawsWorkDesktop = dynamic(() =>
  import('./components/desktop/HowDoesMadPawsWork').then((module) => module.HowDoesMadPawsWork)
);
const HowDoesMadPawsWorkMobile = dynamic(() =>
  import('./components/mobile/HowDoesMadPawsWork').then((module) => module.HowDoesMadPawsWork)
);

export const HowDoesMadPawsWork = (): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();

  return isMediumFromViewport ? <HowDoesMadPawsWorkDesktop /> : <HowDoesMadPawsWorkMobile />;
};
