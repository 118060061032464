import type { ReactElement } from 'react';

type Props = {
  alt: string;
  avifSrc?: string;
  avifType?: 'image/avif';
  fallback: string;
  height?: number | string;
  loading?: 'eager' | 'lazy' | undefined;
  webpSrc?: string;
  webpType?: 'image/webp';
  width?: number | string;
};

export const ImageWithFallback = ({
  avifSrc,
  avifType = 'image/avif',
  alt,
  fallback,
  height = '100%',
  loading = 'lazy',
  webpType = 'image/webp',
  webpSrc,
  width = '100%',
}: Props): ReactElement => (
  <picture>
    <source srcSet={avifSrc} type={avifType} />
    <source srcSet={webpSrc} type={webpType} />
    <img alt={alt} height={height} loading={loading} src={fallback} width={width} />
  </picture>
);
