import { useLargeFromMediaQuery } from '@madpaws/design-system';

import { SearchFiltersForm } from '~/components/SearchFiltersForm/SearchFiltersForm/SearchFiltersForm';

import styles from './DesktopSearchWidgetWrapper.module.css';

import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

type Props = {
  handleFiltersSave: (searchFilters: SearchFilters) => void;
  searchFilters: SearchFilters;
};

export const DesktopSearchWidgetWrapper = ({
  handleFiltersSave,
  searchFilters,
}: Props): ReactElement | null => {
  const isLargeFromViewport = useLargeFromMediaQuery();

  if (!isLargeFromViewport) {
    return null;
  }

  return (
    <div className={styles.root}>
      <SearchFiltersForm currentValues={searchFilters} onFilterSubmit={handleFiltersSave} />
    </div>
  );
};
