import { yupToFormErrors } from 'formik';
import * as yup from 'yup';

import {
  chronologyValidationObject,
  locationValidationObject,
  petTypesValidationObject,
  serviceValidationObject,
} from '~/common/utils/validation';

import type { FormikErrors } from 'formik';
import type { SearchFilters } from '~/common/types/search';

export const searchWidgetValidationSchema = yup.object({
  location: locationValidationObject,
  service: serviceValidationObject,
  petTypes: petTypesValidationObject,
  chronology: chronologyValidationObject,
});

export const validateSearchWidget = (
  values: SearchFilters
): Promise<FormikErrors<SearchFilters> | null> =>
  searchWidgetValidationSchema
    .validate(values, { abortEarly: false })
    .then(() => null)
    .catch((errors: unknown) => {
      if (global.document !== undefined) {
        // scroll to the error element in the next tick to give formik and react
        // some time to set error into the dom
        setTimeout(() => {
          const selector = global.document.querySelector('[data-error-message]');
          if (selector) {
            selector.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }, 0);
      }
      return yupToFormErrors(errors);
    });
